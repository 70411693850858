import React  from 'react';


// Shared Components
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from "../components/seo"

// Images
import Backdrop from '../images/resources/how-it-works/backdrop.inline.svg';
import Contact from '../images/resources/compatible-central-stations/train.png';
import YourSvg from '../images/logo/logo.inline.svg';

export default function About() {
    return(
    <div>
        <Seo title="About" description="Learn more about Tech Tester and our team." />
        <Header/>
        {/* Hero section */}
        <div className="relative bg-myGray-300">
            <div className={"relative z-10 pt-24 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"}>
                <div className={"my-auto text-left "}>
                    <h1 className={"md:text-5xl text-4xl text-myGray-600 font-bold"}>About Us</h1>
                    <h3 className={"text-myGray-600 text-lg pt-10"}> Tech Tester is powered by <span><a className='hover:underline' href={'https://kirkwork.co'}>kirkwork</a></span>, with offices located in Syracuse, NY.</h3>

                </div>
                <div className={"m-auto relative"}>
                    <YourSvg className={'h-72 pt-6 fill-myGray-400'}/>
                </div>
            </div>
            <Backdrop
                className={'fill-myGray-250 object-scale-down absolute z-2 top-36 md:top-0 right-10 md:right-52 lg:right-96 h-80 w-auto'}/>
        </div>
        {/*END HERO*/}

        {/* Alternating Feature Sections */}
        <div className="relative overflow-hidden">
            <div aria-hidden="true" className="absolute z-10 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"/>

            {/* START main section */}
            <div className={'justify-center place-items-center my-36 mx-auto max-w-5xl'}>
                <div className={'mx-12'}>
                    <p>Tech Tester is a utility that grew from an idea about how to solve a problem in the alarm industry, specifically with alarm companies that use wholesale monitoring. The problem is how to protect your account data and your subscriber base. Technicians, as are most skilled positions in today’s market, are tough to find, and harder to retain. What happens when one of your technicians leaves and goes to work for another company? Does all of your account data go with them? Not with Tech Tester! </p><br/>
                    <p>Many Central Stations have applications that are designed for technicians in the field, which give them access to your entire account base. Simple account searches or account lists present all of the data easily. We have found that some alarm companies don’t let their technicians use these products for this reason! Some of these apps are better than others, but they are riddled with clunky user interfaces and lack luster support.</p><br/>
                    <p>We at Tech Tester are software developers, it is what we do, and what we take pride in. Tech Tester utilizes intuitive user interfaces, industry leading technology, with an accessible and agile support team. Try Tech Tester for free today and see the difference!</p>
                </div>

            </div>
            {/* END main section */}

            <div className="relative mt-24 py-24 bg-myGray-200">
                <div className="relative z-10 grid grid-cols-1 text-center place-items-center">
                    <div>
                        <img
                            className="w-96 h-auto"
                            src={Contact}
                            alt="Tech Tester App User Interface"
                        />
                    </div>
                    <div className="mt-6 p-10">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                            Interested in working with or partnering with us?
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 max-w-5xl">
                            Feel free to reach out anytime to support@alarmtechtester.com.                          </p>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    )

}